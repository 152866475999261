import React from 'react'
import styled from 'styled-components'
import { Button } from '../index'
import { ArrowLeft, ArrowRight } from '../icons'

function LightButtonBackground(props) {
  return (
    <svg
      width={56}
      height={56}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={28} cy={28} r={27.5} stroke="url(#prefix__paint0_linear2)" />
      <defs>
        <linearGradient
          id="prefix__paint0_linear2"
          x1={0}
          y1={28}
          x2={56}
          y2={28}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAFAFA" />
          <stop offset={1} stopColor="#FAFAFA" stopOpacity={0} />
        </linearGradient>
      </defs>
    </svg>
  )
}

const Container = styled.div`
  border-radius: 100vw;
  position: relative;
  height: 56px;
  width: 56px;
`

const StyledButton = styled(Button)`
  & button {
    background-color: transparent;
    color: #fff;
  }

  &:hover::before {
    background: transparent;
  }
  background: transparent;
  height: 100%;
  width: 100%;

  & svg {
    display: block;
    margin-left: ${({ left }) => (left ? '-7px' : '-3px')};
    margin-top: 2px;
  }
`
const StyledLightButtonBackground = styled(LightButtonBackground)`
  position: absolute;
  left: 0;
  top: 0;
`

export default function LightSliderButton(props) {
  const { left, onClick } = props
  return (
    <Container>
      <StyledLightButtonBackground />
      <StyledButton onClick={onClick} left={left}>
        {left ? <ArrowLeft /> : <ArrowRight />}
      </StyledButton>
    </Container>
  )
}
