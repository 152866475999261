import * as React from 'react'

function LinkedinLight(props) {
  return (
    <svg
      width={22}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.931.67a10.16 10.16 0 100 20.32 10.16 10.16 0 000-20.32zm-3 15.36h-2.47V8.59h2.47v7.44zm-1.23-8.46a1.29 1.29 0 111.42-1.29 1.28 1.28 0 01-1.42 1.29zm10.23 8.46h-2.48v-4c0-1-.36-1.69-1.25-1.69a1.35 1.35 0 00-1.27.91 1.589 1.589 0 00-.08.6v4.16h-2.48V8.57h2.48v1.05a2.44 2.44 0 012.23-1.23c1.63 0 2.85 1.06 2.85 3.35v4.29z"
        fill="#fff"
      />
    </svg>
  )
}

export default LinkedinLight
