import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import ButtonSlider from '../button-slider'
import VerticalDots from '../vertical-dots'
import HorizontalSlider from '../horizontal-slider'
import { black } from '../../styling/colors'
import { When } from '../../styling/when'
import { useMediaMatch } from '../../util'
import Img from 'gatsby-image'
import { motion } from 'framer-motion'

const GoToCategory = styled.div`
  position: absolute;
  z-index: 16;
  display: flex;
  justify-content: center;
  bottom: 125px;
  transform: translateY(50%);
  transition: opacity 0.6s;
  opacity: 0;
`

const LogoContainer = styled.div`
  z-index: 15;
  position: absolute;
  width: 100%;
  height: 125px;
  bottom: 0;
  display: flex;
  justify-content: center;
`

const ItemImage = styled(Img)`
  position: absolute;
  width: 80%;
  /* left: 50%;
  transform: translateX(-50%); */
  /* width: 100% !important; */
`

const Item = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  transition: left 0.2s;

  & div > div > div {
    display: none;
  }

  ${When.Medium}{
    div > div > div {
      display: unset;
    }
  }

  &:hover ${GoToCategory} {
    opacity: 1;
  }
`

const SliderInnnerContainerStyles = css`
  width: 214px;
  height: 150px;

  ${When.Medium} {
    // height: 300px;
    margin-top: 75px;
  }

  ${When.Large} {
    margin-top: unset;
    width: 415px;
    height: 300px;
  }
`

const fontStyleByCategory = {
  'still-wine': css`
    font-family: 'Abril Fatface';
    font-weight: 400;
    font-size: 28px;
    bottom: 51px;
  `,
  spirits: css`
    font-family: 'Comfortaa';
    font-weight: 400;
    font-size: 28px;
    bottom: 50px;
  `,
  'sparkling-wine': css`
    font-family: 'Cinzel';
    font-weight: 400;
    font-size: 28px;
    bottom: 30px;

    ${When.Large} {
      bottom: 48px;
    }
  `,
  juice: css`
    font-family: 'Lobster';
    font-weight: 400;
    font-size: 40px;
    bottom: 45px;
  `,
  beer: css`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 32px;
    bottom: 46px;
  `,
  jar: css`
    font-family: 'Teko';
    font-weight: 400;
    font-size: 48px;
    bottom: 36px;
  `,
}

const SliderItem = styled.div``

const imageStylesBySlug = {
  'still-wine': css`
    width: 99px;
    position: relative;
    bottom: -20px;

    ${When.Medium} {
      position: static;
    }
  `,
  spirits: css`
    width: 127px;
  `,
  'sparkling-wine': css`
    width: 137px;
    bottom: -20px;
  `,
  juice: css`
    width: 119px;
  `,
  beer: css`
    position: relative;
    bottom: -20px;
    width: 98px;
  `,
  jar: css`
    width: 119px;
  `,
}

const StyledGatsbyImg = styled(Img)`
width: 150px;
height: 150px;
min-width: 150px;

${When.Large} {
  width: 300px;
  height: 300px;
  min-width: 300px;
}
border-radius: 100%;
overflow: hidden;
  /* width: 100% !important; */
`

const StyledDots = styled(VerticalDots)`
  display: none;
  position: absolute;
  top: 50%;
  right: -400px;
  transform: translateY(-50%);

  margin-top: -100px;

  ${When.Large} {
    display: flex;
  }
`

const CountContainer = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface Props {
  showSliderItems?: number
}

export default function DecorationsSlider({ showSliderItems, onItemClick = false }: Props) {
  const {
    allStrapiDecorationsSldierPhotos: { edges },
  } = useStaticQuery(query)

  const { medium, large } = useMediaMatch()

  const [activePage, setActivePage] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)

  const handleNumberOfPagesChange = (numberOfPages: number) => {
    setActivePage(Math.min(activePage, numberOfPages - 1))
    setNumberOfPages(numberOfPages)
  }

  const slides: {
    id: string
    fluid: any
    photoUrl: string
  }[] = edges.map(({ node }) => {
    const {
      // productCategoryPhoto: { provider_metadata },
      id,
      name,
      photo: { url: photoUrl },
    } = node

    return {
      id,
      name,
      fluid: node.photo.localFile.childImageSharp.fluid,
      photoUrl,
    }
  })

  return (
    <>
      <StyledDots numberOfDots={numberOfPages} activeDot={activePage} />
      <HorizontalSlider
        itemListContainerStyles={SliderInnnerContainerStyles}
        horizontalAlign="bottom"
        onChangePage={x => setActivePage(x)}
        onPageNumCalculated={x => handleNumberOfPagesChange(x)}
        page={activePage}
        items={slides}
        pageSize={showSliderItems}
        slideBy="page"
        keys={item => item.id}
        onItemClick={onItemClick}
        itemTemplate={({ id, fluid }) => (
          <SliderItem
            style={{ display: 'block', overflow: 'hidden' }}
            to="#"
          >
            <Item key={id}>
              <StyledGatsbyImg fluid={fluid} />
              <GoToCategory>
                {/* <ButtonSlider colors="whiteOnBlack" direction="right" /> */}
              </GoToCategory>
            </Item>
          </SliderItem>
        )}
      />
      <CountContainer>
        <span>{slides.length > 9 && (activePage + 1) < 10 ? `0${activePage + 1}` : activePage + 1}</span>
        <span style={{
          marginLeft: 8, marginRight: 8, color: "#9E9E9E", fontWeight: 500
        }}>|</span>
        <span>{slides.length}</span>
      </CountContainer>
    </>
  )
}

const query = graphql`
  query {
    allStrapiDecorationsSldierPhotos(sort: { fields: orderNumber, order: ASC }) {
      edges {
        node {
          id
          photo {
            url
          }
          photo {
            localFile {
              childImageSharp {
                fluid(
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
