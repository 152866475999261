import * as React from 'react'

function InstagramLight(props) {
  return (
    <svg
      width={21}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.551 6.48a6.89 6.89 0 00-.46-2.4 4.78 4.78 0 00-1.14-1.76 4.87 4.87 0 00-1.75-1.14 7.44 7.44 0 00-2.4-.46c-1.06-.05-1.39-.06-4.08-.06-2.69 0-3 0-4.08.06a7.18 7.18 0 00-2.4.46 4.87 4.87 0 00-1.75 1.14 5 5 0 00-1.14 1.75 7.44 7.44 0 00-.42 2.41c-.05 1.06-.06 1.4-.06 4.08s0 3 .05 4.08c.02.82.176 1.631.46 2.4a5.001 5.001 0 001.15 1.75 4.62 4.62 0 001.75 1.14 6.94 6.94 0 002.4.46c1 0 1.39.06 4.08.06 2.69 0 3 0 4.07-.06a6.89 6.89 0 002.4-.46 5.063 5.063 0 002.9-2.89c.285-.769.44-1.58.46-2.4v-4.08c0-2.69.01-3.02-.04-4.08zm-9.83 9.16a5.09 5.09 0 115.08-5.09 5.08 5.08 0 01-5.08 5.1v-.01zm5.28-9.18a1.19 1.19 0 111.19-1.19 1.18 1.18 0 01-1.19 1.21v-.02z"
        fill="#fff"
      />
    </svg>
  )
}

export default InstagramLight
