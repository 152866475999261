import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import ButtonSlider from '../button-slider'
import VerticalDots from '../vertical-dots'
import HorizontalSlider from '../horizontal-slider'
import { black } from '../../styling/colors'
import { When } from '../../styling/when'
import { useMediaMatch } from '../../util'
import Img from 'gatsby-image'
import { motion } from 'framer-motion'

const GoToCategory = styled.div`
  position: absolute;
  z-index: 16;
  display: flex;
  justify-content: center;
  bottom: 125px;
  transform: translateY(50%);
  transition: opacity 0.6s;
  opacity: 0;
`

const LogoContainer = styled.div`
  z-index: 15;
  position: absolute;
  width: 100%;
  height: 125px;
  bottom: 0;
  display: flex;
  justify-content: center;
`

const ItemLogo = styled.img`
  width: 110%;
  /* Do I really need this? */
  transform: translateX(-5%);
  bottom: 100px;
`

const BlackBarContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 125px;
  overflow-y: visible;
  z-index: 14;
`

const BlackBar = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  background: ${black};
  border-radius: 4px 4px 0px 0px;
`

const ItemImage = styled(Img)`
  position: absolute;
  width: 80%;
  /* left: 50%;
  transform: translateX(-50%); */
  /* width: 100% !important; */
`

const Item = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  transition: left 0.2s;

  & div > div > div {
    display: none;
  }

  ${When.Medium}{
    div > div > div {
      display: unset;
    }
  }

  &:hover ${GoToCategory} {
    opacity: 1;
  }
`

const SliderInnnerContainerStyles = css`
  width: 249px;

  ${When.Medium} {
    width: 620px;
  }

  ${When.Large} {
    width: 100%;
  }
`

const categorySpecificStyles = {
  'still-wine': css`
    ${ItemImage} {
      top: 0px;
    }
  `,
  spirits: css`
    ${ItemImage} {
      top: 0x;
      /* top: -30px; */
    }
  `,
  'sparkling-wine': css`
    ${ItemImage} {
      top: 0x;
    }
  `,
  juice: css`
    ${ItemImage} {
      top: 0x;
      /* top: 100px; */
    }
  `,
  beer: css`
    ${ItemImage} {
      top: 0x;
      /* top: 30px; */
    }
  `,
  jar: css`
    ${ItemImage} {
      top: 0x;
      /* top: 250px; */
    }
  `,
}

const fontStyleByCategory = {
  'still-wine': css`
    font-family: 'Abril Fatface';
    font-weight: 400;
    font-size: 28px;
    bottom: 51px;
  `,
  spirits: css`
    font-family: 'Comfortaa';
    font-weight: 400;
    font-size: 28px;
    bottom: 50px;
  `,
  'sparkling-wine': css`
    font-family: 'Cinzel';
    font-weight: 400;
    font-size: 28px;
    bottom: 30px;

    ${When.Large} {
      bottom: 48px;
    }
  `,
  juice: css`
    font-family: 'Lobster';
    font-weight: 400;
    font-size: 40px;
    bottom: 45px;
  `,
  beer: css`
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 32px;
    bottom: 46px;
  `,
  jar: css`
    font-family: 'Teko';
    font-weight: 400;
    font-size: 48px;
    bottom: 36px;
  `,
}

const ItemName = styled.div`
  ${({ slug }) => fontStyleByCategory[slug]};
  position: absolute;
  color: white;
  white-space: normal;

  ${When.Large} {
    white-space: nowrap;
  }
`

const StyledLink = styled(Link)``

const imageStylesBySlug = {
  'still-wine': css`
    width: 99px;
    position: relative;
    bottom: -20px;

    ${When.Medium} {
      position: static;
    }
  `,
  spirits: css`
    width: 127px;
  `,
  'sparkling-wine': css`
    width: 137px;
    bottom: -20px;
  `,
  juice: css`
    width: 119px;
  `,
  beer: css`
    position: relative;
    bottom: -20px;
    width: 98px;
  `,
  jar: css`
    width: 119px;
  `,
}

const StyledGatsbyImg = styled(Img)`
  ${({ slug }) => (imageStylesBySlug[slug] ? imageStylesBySlug[slug] : '')};
  /* width: 100% !important; */
`

const StyledDots = styled(VerticalDots)`
  display: none;
  position: absolute;
  top: 0;
  right: 0;

  ${When.Large} {
    display: flex;
  }
`

interface Props {
  showSliderItems?: number
}

export default function CategorySlider({ showSliderItems }: Props) {
  const {
    allFile: { edges: files },
    allStrapiProductCategory: { edges },
  } = useStaticQuery(query)

  const { medium, large } = useMediaMatch()

  const [activePage, setActivePage] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)

  const handleNumberOfPagesChange = (numberOfPages: number) => {
    setActivePage(Math.min(activePage, numberOfPages - 1))
    setNumberOfPages(numberOfPages)
  }

  const categories: {
    id: string
    name: string
    fixedLarge: any
    fixedMedium: any
    fixedSmall: any
    fluid: any
    logoUrl: string
    slug: string
  }[] = edges.map(({ node }) => {
    const {
      // productCategoryPhoto: { provider_metadata },
      id,
      name,
      fields: { slug },
      logo: { url: logoUrl },
    } = node

    return {
      id,
      name,
      fluid: node.productCategoryPhoto.localFile.childImageSharp.fluid,
      fixedLarge:
        node.productCategoryPhoto.localFile.childImageSharp.fixedLarge,
      fixedMedium:
        node.productCategoryPhoto.localFile.childImageSharp.fixedMedium,
      fixedSmall:
        node.productCategoryPhoto.localFile.childImageSharp.fixedSmall,
      logoUrl,
      slug,
    }
  })

  const imageBySlug = files.reduce((prev, { node }) => {
    prev[node.name] = node.childImageSharp.fluid
    return prev
  }, {})

  return (
    <>
      <StyledDots numberOfDots={numberOfPages} activeDot={activePage} />
      <HorizontalSlider
        itemListContainerStyles={SliderInnnerContainerStyles}
        horizontalAlign="bottom"
        onChangePage={x => setActivePage(x)}
        onPageNumCalculated={x => handleNumberOfPagesChange(x)}
        page={activePage}
        items={categories}
        pageSize={showSliderItems}
        slideBy="page"
        keys={item => item.id}
        itemTemplate={({ name, slug }) => (
          <StyledLink
            css={categorySpecificStyles[slug]}
            to={slug}
            style={{ display: 'block' }}
          >
              <Item key={name}>
                <StyledGatsbyImg fluid={imageBySlug[slug]} slug={slug} />
                <LogoContainer>
                  <ItemName slug={slug}>{name}</ItemName>
                  {/* <ItemLogo src={logoUrl}></ItemLogo> */}
                </LogoContainer>
                <GoToCategory>
                  {/* <ButtonSlider colors="whiteOnBlack" direction="right" /> */}
                </GoToCategory>
              </Item>
          </StyledLink>
        )}
        after={
          <BlackBarContainer>
            <BlackBar />
          </BlackBarContainer>
        }
      />
    </>
  )
}

const query = graphql`
  query {
    allFile(filter: { relativePath: { glob: "images/category-slider/*" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
    allStrapiProductCategory(sort: { fields: orderNumber, order: ASC }) {
      edges {
        node {
          id
          name
          fields {
            slug
          }
          logo {
            url
          }
          productCategoryPhoto {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxHeight: 1080) {
                  ...GatsbyImageSharpFluid_noBase64
                }
                fixedLarge: fixed(width: 300, height: 600, cropFocus: NORTH) {
                  ...GatsbyImageSharpFixed_noBase64
                }
                fixedMedium: fixed(width: 213, height: 426, cropFocus: NORTH) {
                  ...GatsbyImageSharpFixed_noBase64
                }
                fixedSmall: fixed(width: 249, height: 498, cropFocus: NORTH) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
