import * as React from 'react'

function YoutubeLight(props) {
  return (
    <svg
      width={22}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.222 12.9l2.47-1.63a.54.54 0 000-.88l-2.47-1.63a.45.45 0 00-.67.44v3.28a.45.45 0 00.67.42z"
        fill="#fff"
      />
      <path
        d="M10.931.67a10.16 10.16 0 100 20.32 10.16 10.16 0 000-20.32zm6.35 10.17a16.802 16.802 0 01-.26 3.05 1.57 1.57 0 01-1.12 1.12c-1.658.201-3.329.292-5 .27a36.004 36.004 0 01-5-.28 1.55 1.55 0 01-.97-1.12 18 18 0 010-6.1 1.58 1.58 0 011.12-1.13 38.7 38.7 0 015-.26c1.671-.022 3.342.068 5 .27a1.57 1.57 0 011.12 1.12c.148 1.013.198 2.037.15 3.06h-.04z"
        fill="#fff"
      />
    </svg>
  )
}

export default YoutubeLight
