import React from 'react'
import styled from 'styled-components'
import { black, grayOnWhite, white } from '../../styling/colors'

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 12px;
  transition: background 0.4s;
  background: ${({ active }) => active ? black : '#f5f5f5'};
  color: ${({ active }) => active ? white : black};
  border-radius: 22px;
  border: 0;
  outline: 0;
  cursor: pointer;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;

  &:hover {
    ${({ active }) => !active && 'background: #e0e0e0;'};
  }
`

interface Props {
  children?: string
  active?: string
  className?: string
  onClick?: (e) => void
}

export default function Button({ children, active, className, onClick }: Props) {
  return (
    <StyledButton onClick={onClick} active={!!active} className={className}>
      {children}
    </StyledButton>
  )
}
