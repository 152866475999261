import React from 'react'
import styled from 'styled-components'
import { almostWhiteOnGray, grayOnBlack } from '../../styling/colors'
import { PaperPlane } from '../icons'

const Label = styled.div`
  color: ${almostWhiteOnGray};
  font-weight: 600;
  font-size: 14px;
  transition: color 0.2s;
`

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;

  & svg {
    stroke: ${almostWhiteOnGray};
    transition: stroke 0.2s;
    padding-right: 9px;
  }

  &:hover svg,
  &:active svg {
    stroke: ${grayOnBlack};
  }

  &:hover ${Label}, &:active ${Label} {
    color: ${grayOnBlack};
  }
`

interface Props {
  className?: string
  children?: JSX.Element | JSX.Element[]
  onClick?: (e) => void
}

export default function ButtonSend({ className, children, onClick }) {
  return (
    <StyledButton onClick={onClick} className={className}>
      <PaperPlane stroke={null} />
      <Label>{children}</Label>
    </StyledButton>
  )
}
