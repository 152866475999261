import { useLayoutEffect, useState } from 'react'

export function useMediaMatch() {
  const [matches, setMatches] = useState<{
    medium: boolean
    large: boolean
  }>({ medium: false, large: false })

  useLayoutEffect(() => {
    if (typeof window !== 'undefined') {
      const mediumSize = window.matchMedia('(min-width: 768px)')
      const largeSize = window.matchMedia('(min-width: 1120px)')
      const listenerMedium = e =>
        setMatches(state => ({ ...state, ...{ medium: e.matches } }))
      const listenerLarge = e =>
        setMatches(state => ({ ...state, ...{ large: e.matches } }))
      mediumSize.addEventListener('change', listenerMedium)
      largeSize.addEventListener('change', listenerLarge)

      setMatches({ medium: mediumSize.matches, large: largeSize.matches })

      return () => {
        mediumSize.removeEventListener('change', listenerMedium)
        largeSize.removeEventListener('change', listenerLarge)
      }
    }
  }, [])

  return matches
}
