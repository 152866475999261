import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { black, white } from '../styling/colors'
import { navigate } from '@reach/router'
import { Arrow2Left } from './icons'
import { motion } from 'framer-motion'

const GoBackWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const GoBackLink = styled.a`
  color: ${({ color }) => color};
  padding-bottom: 4px;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    color: ${({ color }) => color};
    cursor: pointer;
  }
`

interface Props {
  children?: string | JSX.Element | JSX.Element[]
  color?: string
  className?: string
}

export default function GoBack({ children = 'Back', color = black, className }: Props) {
  return (
    <GoBackWrapper className={className} initial="rest" whileHover="hover" animate="rest">
      <motion.div variants={{ rest: { x: -4 }, hover: { x: -8 } }}>
        <Arrow2Left stroke={color} width={16} height={16} />
      </motion.div>
      <GoBackLink color={color} onClick={() => navigate(-1)}>
        {children}
      </GoBackLink>
    </GoBackWrapper>
  )
}
