import React, { useState } from 'react'
import { Globe } from '../icons'
import styled from 'styled-components'
import { Popover } from '@material-ui/core'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const GlobeWrapper = styled.div`
  padding-right: 5px;
`

const DisplayText = styled.span`
  font-size: 14px;
  color: white;
`

const PopoverMessage = styled.div`
  padding: 10px 20px;
`

export default function LanguageSwitcher() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  
  return (
    <Container
      onMouseEnter={e => setAnchorEl(e.currentTarget)}
      onMouseLeave={() => setAnchorEl(null)}
    >
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => setAnchorEl(null)}
      >
        <PopoverMessage>Other languages coming soon!</PopoverMessage>
      </Popover>
      <GlobeWrapper>
        <Globe />
      </GlobeWrapper>
      <DisplayText>ENG</DisplayText>
    </Container>
  )
}
