import * as React from 'react'

function FacebookLight(props) {
  return (
    <svg
      width={21}
      height={21}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.42.67a10.16 10.16 0 100 20.32 10.16 10.16 0 000-20.32zm3.87 5.17h-1.36c-1.1 0-1.32.52-1.32 1.29v1.69h2.63v2.66h-2.63v6.81H8.83v-6.81H6.54V8.83h2.29v-2a3.2 3.2 0 013.42-3.5h2V5.8l.04.04z"
        fill="#fff"
      />
    </svg>
  )
}

export default FacebookLight
