import React from "react";
import styled from 'styled-components'
import Button from "../button";

function LightButtonBackground(props) {
    return (
      <svg
        width={188}
        height={61}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <g filter="url(#prefix__filter0_d)">
          <rect
            x={183.5}
            y={52.5}
            width={179}
            height={52}
            rx={26}
            transform="rotate(-180 183.5 52.5)"
            stroke="url(#prefix__paint0_linear)"
            shapeRendering="crispEdges"
          />
        </g>
        <defs>
          <linearGradient
            id="prefix__paint0_linear"
            x1={184}
            y1={79.5}
            x2={349.984}
            y2={79.5}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset={0.002} stopColor="#FAFAFA" stopOpacity={0} />
            <stop offset={1} stopColor="#FAFAFA" />
          </linearGradient>
          <filter
            id="prefix__filter0_d"
            x={0}
            y={0}
            width={188}
            height={61}
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity={0} result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy={4} />
            <feGaussianBlur stdDeviation={2} />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          </filter>
        </defs>
      </svg>
    )
}


const Container = styled.div`
border-radius: 100vw;
position: relative;
height: 54px;
width: 180px;
`

const StyledButton = styled(Button)`
& button {
  background-color: transparent;
  color: #fff;
  font-weight: 600;
}

&:hover::before {
  background: transparent;
}
background: transparent;
height: 100%;
width: 100%;
`
const StyledLightButtonBackground = styled(LightButtonBackground)`
position: absolute;
left: -5px;
top: 0;
`

export default function LightButton ({ onClick, children }){
    return <Container>
        <StyledLightButtonBackground/>
        <StyledButton onClick={onClick}>
            {children}
        </StyledButton>
    </Container>
}