import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ButtonSlider } from '..'
import { When } from '../../styling/when'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: ${({ horizontalAlign }) => {
    switch (horizontalAlign) {
      case 'top':
        return 'flex-start'
      case 'middle':
        return 'center'
      case 'bottom':
        return 'flex-end'
    }
  }};
  align-items: flex-end;
  width: 100%;
`

const GoToCategory = styled.div`
  position: absolute;
  z-index: 16;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 125px;
  transform: translateY(50%);
  transition: opacity 0.6s;
  opacity: 0;
`

const ItemContainer = styled(motion.div)`
  position: relative;
  text-align: center;
  display: inline-block;
  height: 100%;
  width: calc(100% / ${props => props.pageSize});
  transition: left 0.2s;
  margin: 0 auto;

  left: ${props => -props.offset * (100 / props.pageSize)}%;

  &:hover ${GoToCategory} {
    opacity: 1;
  }
  ${({ clickable }) => clickable ? 'cursor: pointer;' : ''}
`

const DynamicHeightContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
`

const HorizontalItemListContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  ${({ styles }) =>
    styles}/* TODO: this should be configured from outside the component */
  /* ${When.Large} {
    width: 100%;
  } */
`

const HorizontalItemList = styled(motion.div)`
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  width: 100%;

  /* transform: translateX(calc(100% / ${props => props.pageSize})); */

  ${({ styles }) => styles}
`

const ButtonSliderLeft = styled(ButtonSlider)`
  /* position: absolute; */
  /* top: 50%;
  left: 3%; */
  /* transform: translateY(-50%); */
  /* position: relative; */
  ${({ disabled }) => disabled ? 'pointer-events: none;' : ''}
`

const ButtonSliderRight = styled(ButtonSlider)`
  /* position: absolute; */
  /* right: 3%;
  top: 50%;
  transform: translateY(-50%); */
  /* position: relative; */
  ${({ disabled }) => disabled ? 'pointer-events: none;' : ''}
`

const EmptyPlaceholder = styled.div`
  width: 100%;
  text-align: center;
  &::before {
    content: 'Empty...';
  }
`

interface Props<T> {
  pageSize: number
  slideBy: 'item' | 'page'
  items: T[]
  keys: (item: T) => string
  itemTemplate: (item: T, isVisible?: boolean) => JSX.Element
  page?: number
  onChangePage?: (newPage: number) => void
  onPageNumCalculated?: (pageNum: number) => void
  horizontalAlign?: 'top' | 'middle' | 'bottom'
  itemListContainerStyles?: string
  itemListStyles?: string
  emptyPlaceholder?: JSX.Element
  after?: JSX.Element
  showControls?: boolean
  className?: string
}

export default function HorizontalSlider<T>({
  pageSize,
  slideBy,
  items,
  itemTemplate,
  page,
  onChangePage,
  onPageNumCalculated,
  emptyPlaceholder,
  horizontalAlign,
  itemListContainerStyles,
  itemListStyles,
  after,
  showControls,
  className,
  enableLeftArrow,
  enableRightArrow,
  slideLeftFail = () => null,
  slideRightFail = () => null,
  onItemClick = false,
  keys,
}: Props<T>) {
  const [activePage, setActivePage] = useState<number>(0)
  const sliderMaxOffset = items.length - pageSize
  const withControls = showControls === undefined ? true : showControls

  let numberOfPages =
    slideBy === 'item'
      ? 1 + items.length - pageSize // plus one for default position
      : Math.ceil(items.length / pageSize)

  const currentPage = Math.min(
    // controlled vs uncontrolled
    onChangePage !== undefined ? page : activePage,
    numberOfPages - 1
  )

  let offset = Math.min(
    sliderMaxOffset,
    slideBy === 'item' ? currentPage : currentPage * pageSize
  )

  if (items.length - pageSize <= 0) {
    numberOfPages = 1
    offset = 0
  }

  const canSlideRight = offset < sliderMaxOffset
  const canSlideLeft = currentPage > 0

  const slideRight = () => {
    if (canSlideRight) {
      if (onChangePage !== undefined) {
        onChangePage(currentPage + 1)
      } else {
        setActivePage(currentPage + 1)
      }
    } else {
      slideRightFail()
    }
  }

  const slideLeft = () => {
    if (canSlideLeft) {
      if (onChangePage !== undefined) {
        onChangePage(currentPage - 1)
      } else {
        setActivePage(currentPage - 1)
      }
    } else {
      slideLeftFail()
    }
  }

  useEffect(() => {
    if (onPageNumCalculated) {
      onPageNumCalculated(numberOfPages)
    }
  }, [numberOfPages])

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  }

  const itemAnim = {
    hidden: { opacity: 0, x: 80 },
    show: { opacity: 1, x: 0 },
  }

  return (
    <Container
      className={className}
      horizontalAlign={horizontalAlign || 'middle'}
    >
      <DynamicHeightContainer>
        {withControls && (
          <ButtonSliderLeft
            onClick={() => slideLeft()}
            direction="left"
            colors="outlineOnWhite"
            disabled={enableLeftArrow ? false : !canSlideLeft}
          />
        )}
        <HorizontalItemListContainer styles={itemListContainerStyles}>
          {items.length ? (
            <HorizontalItemList
              styles={itemListStyles}
              variants={container}
              initial="hidden"
              animate="show"
            >
              {items.map((item, index) => (
                <ItemContainer
                  key={keys(item)}
                  offset={offset}
                  pageSize={pageSize}
                  variants={itemAnim}
                  clickable={onItemClick}
                  onClick={() => onItemClick && onItemClick(item, index)}
                >
                  {itemTemplate(
                    item,
                    index >= offset && index < offset + pageSize
                  )}
                </ItemContainer>
              ))}
            </HorizontalItemList>
          ) : (
            emptyPlaceholder || <EmptyPlaceholder />
          )}
        </HorizontalItemListContainer>
        {withControls && (
          <ButtonSliderRight
            onClick={() => slideRight()}
            direction="right"
            colors="outlineOnWhite"
            disabled={enableRightArrow ? false : !canSlideRight}
          />
        )}
      </DynamicHeightContainer>
      {after}
    </Container>
  )
}
