import React from 'react'
import { TextField as MaterialUITextField } from '@material-ui/core'
import styled from 'styled-components'

const StyledMaterialUITextField = styled(MaterialUITextField)`
  input {
    color: #ffffff;
  }
  .MuiFormLabel-root {
    color: #9e9e9e;
  }
  .MuiFormLabel-root.Mui-focused {
    color: #9e9e9e;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid #616161;
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid #fff;
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid #888;
  }
`

export default function TextField(props) {
  return <StyledMaterialUITextField id="standard-basic" {...props} />
}
