import { useState, useCallback } from "react";
import { ContactPageEmailForm, ContactUsAboutBottleEmailForm } from "../interfaces";
import { graphql, useStaticQuery } from 'gatsby'

export function useFormSubmit() {

    const { allStrapiFormEmails: { edges: [ { node: formSubmitEmails } ] } } = useStaticQuery(query);

    const [contactEmailResponseLoading, setContactEmailResponseLoading] = useState<boolean>(false);
    const [productEmailResponseLoading, setProductEmailResponseLoading] = useState<boolean>(false);

    const sendEmail = useCallback(async (email, payload, setLoading) => {
        try {
            const rawRes = await fetch(`https://formsubmit.co/ajax/${email}`, {
                method: "POST",
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(payload)
            })
    
            const response = await rawRes.json();
    
            return response.success === "true";
        } catch (error) {
            console.error(error);
            return false;
        } 
        finally {

        }
    }, [])

    const sendContactUsEmail = useCallback(async (data: ContactPageEmailForm) => {
        const payload = {
                _subject: data.subject,
            _template: "table",
            _replyto: data.email,
            ...data,
        }
        const didSucceed = await sendEmail(formSubmitEmails.contact, payload, setContactEmailResponseLoading);
        return didSucceed;
    }, [setContactEmailResponseLoading])

    const sendContactUsAboutBottleEmail = useCallback(async (data: ContactUsAboutBottleEmailForm) => {
        const payload = {
                _subject: `${data.bottle} - ${data.subject}`,
            _template: "table",
            _replyto: data.email,
            ...data,
        }
        const didSucceed = await sendEmail(formSubmitEmails.product, payload, setProductEmailResponseLoading);
        return didSucceed;
    }, [setProductEmailResponseLoading])

  return { sendContactUsEmail, contactEmailResponseLoading, sendContactUsAboutBottleEmail, productEmailResponseLoading, formSubmitEmails }
}


const query = graphql`
  query {
    allStrapiFormEmails {
      edges {
        node {
          product
          career
          contact
        }
      }
    }
  }
`