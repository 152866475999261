import React from 'react'
import styled from 'styled-components'
import GlobalStyle from './global-style'
import Header from './header'

const Container = styled.div`
  overflow-y: auto;
`

const Content = styled.div`
  margin: 0 auto;
  padding-top: 88px;
  box-sizing: border-box;
  height: 100vh;
  position: relative;
`

const EvenVerticalFlex = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  min-height: 670px;
  height: 1100px;
  max-height: 100%;
  ${({ styles }) => styles}
`

export default function LayoutFluid({ children, location, styles = ''}) {
  return (
    <Container>
      <GlobalStyle />
      <Header location={location} />
      <Content>
        <EvenVerticalFlex styles={styles}>{children}</EvenVerticalFlex>
      </Content>
    </Container>
  )
}
