import React from 'react'
import styled from 'styled-components'
import { white } from '../styling/colors'
import GlobalStyle from './global-style'
import Header from './header'
import { Helmet } from 'react-helmet'
import favicon from '../assets/favicon.ico'

const Container = styled.div`
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #9e9e9e;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9e9e9e;
  }
`

const Content = styled.div`
  margin: 0 auto;
  padding-top: 88px;
  max-width: 1120px;
  box-sizing: border-box;
  height: 100vh;
  position: relative;
`

const EvenVerticalFlex = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  min-height: 532px; // 620px - 88px (navbar)
  max-height: 992px; // 1080px - 88px
`

export default function Layout({
  children,
  location,
  backgroundColor,
  title = 'KMC Glass',
  style = {},
  contentStyle = {},
}: {
  children: any
  location: string
  backgroundColor?: string
  title?: string
  style?: object
  contentStyle?: object
}) {
  return (
    <Container style={{ backgroundColor: backgroundColor || white }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <link rel="icon" href={favicon} />
        {/* <link rel="canonical" href="http://mysite.com/example" /> */}
      </Helmet>
      <GlobalStyle />
      <Header location={location} />
      <Content style={contentStyle}>
        <EvenVerticalFlex style={style}>{children}</EvenVerticalFlex>
      </Content>
    </Container>
  )
}
