import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import { black, white } from '../../styling/colors'
import { Minus, Plus } from '../icons'

const BorderWrapper = styled.div`
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  padding: 1px;
  border-radius: 50%;
  position: relative;
  background: linear-gradient(
    270deg,
    #424242 -3.77%,
    rgba(66, 66, 66, 0) 106.78%
  );
`

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${white};
  border-radius: 50%;
  border: 0;
  outline: 0;
  cursor: pointer;
  position: relative;
`

interface Props {
  expanded?: boolean
  onClick?: MouseEventHandler<Element>
}

export default function ButtonProperty({ expanded, onClick }: Props) {
  return (
    <BorderWrapper>
      <StyledButton onClick={onClick}>
      <Plus />
      </StyledButton>
    </BorderWrapper>
  )
}
