import React from 'react'
import styled from 'styled-components'
import { When } from '../../styling/when'
import { Circle } from '../../components/icons'
import { black, grayOnBlack, grayOnWhite, white } from '../../styling/colors'

const VerticalCircles = styled.div`
  display: none;

  ${When.Medium} {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
  }
`

const CircleContainer = styled.div`
  margin-bottom: 6px;
  line-height: 0;

  svg {
    transition: all 0.2s;
  }
`

interface Props {
  numberOfDots: number
  activeDot?: number
  colors?: 'dark' | 'light'
  className?: string
}

export default function VerticalDots({
  numberOfDots,
  activeDot,
  colors,
  className,
}: Props) {
  const setColors = colors || 'dark'

  return (
    <VerticalCircles className={className}>
      {Array(numberOfDots)
        .fill(0)
        .map((_, index) => (
          <CircleContainer key={index}>
            <Circle
              {...(index === activeDot
                ? {
                    fill: setColors === 'dark' ? black : white,
                    width: 8,
                    height: 8,
                  }
                : {
                    fill: setColors === 'dark' ? grayOnWhite : grayOnBlack,
                    width: 4,
                    height: 4,
                  })}
            />
          </CircleContainer>
        ))}
    </VerticalCircles>
  )
}
