import React from 'react'
import styled from 'styled-components'
import { grayOnBlack, grayOnWhite } from '../../styling/colors'

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  background-color: transparent;
  border-radius: 50%;
  border: ${({ stroke }) => (stroke ? `1px solid ${grayOnWhite}` : '0')};
  outline: 0;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover:not(:disabled),
  &:active:not(:disabled) {
    background-color: ${({ colors }) =>
      colors === 'light' ? grayOnWhite : grayOnBlack};
  }

  &:disabled {
    opacity: 0.25;
  }
`

interface Props {
  colors: 'light' | 'dark'
  icon?: JSX.Element
  stroke: boolean
  onClick?: (e) => void
  className?: string
  children?: JSX.Element | JSX.Element[],
  disabled?: boolean
}

export default function ButtonIcon({
  colors,
  icon,
  stroke,
  onClick,
  children,
  className,
  disabled = false,
}: Props) {
  return (
    <StyledButton
      className={className}
      onClick={onClick}
      colors={colors}
      stroke={stroke}
      disabled={disabled}
    >
      {icon || children}
    </StyledButton>
  )
}
