import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { BurgerMenu, Circle, Cross, Logo } from '../icons'
import { white, almostWhiteOnGray, black } from '../../styling/colors'
import LanguageSwitcher from '../language-switcher/language-switcher'
import { When } from '../../styling/when'
import { navigate } from '@reach/router'
import GoBack from '../go-back'

const InnerContainer = styled.div`
  margin: 0 auto;
  max-width: 1120px;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;

  position: relative;
`

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 64px;
`

const LanguageSwitcherWrapper = styled.div`
  align-items: center;
  justify-content: center;
  height: 100%;
  display: none;

  ${When.Large} {
    display: flex;
  }
`

const BurgerMenuButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: 0;
  cursor: pointer;

  ${When.Large} {
    display: none;
  }
`

const ActiveItemText = styled.div`
  color: ${white};
  padding-right: 27px;
`

const Item = styled.li`
  text-align: left;

  ul {
    // ${({ active }) =>
      active === 'Y' ? 'display: block;' : 'display: none;'}
  }

  ${When.Large} {
    text-align: center;
    display: inline;
    ul {
      display: none;
    }
  }
`

const List = styled.ul`
  list-style-type: none;
  margin: 0;
`

const LinkText = styled.div`
  color: ${almostWhiteOnGray};
  line-height: 23px;
`

const ActiveCircle = styled.div`
  visibility: hidden;
  svg {
    display: block;
    margin-top: -6px;
    transition: all 0.4s;
    width: 4px;
    height: 4px;
    margin-left: 16px;
  }

  ${When.Large} {
    svg {
      margin-left: 0;
      opacity: 0;
      width: 0;
      height: 0;
    }
  }
`

const CircleWrapper = styled.div`
  height: 4px;
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  padding-right: 32px;
  font-size: 18px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: center;
  padding-bottom: 15px;

  ${LinkText} {
    color: ${white};
    display: inline-block;
  }

  ${ActiveCircle} {
    display: flex;
    justify-content: center;
    svg {
      margin-top: 0;
    }
  }

  ${({ active }) =>
    active === 'Y' &&
    `
      ${ActiveCircle} {
        visibility: visible;
        opacity: 1;
      }
    `}

  ${When.Large} {
    display: inline-block;
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;

    /* sets the width to what it becomes on hover */
    &::before {
      display: block;
      content: attr(title);
      font-weight: 500;
      height: 1px;
      color: transparent;
      overflow: hidden;
      visibility: hidden;
    }

    &:hover,
    &:visited,
    &:link,
    &:active {
      text-decoration: none;
    }

    &:hover,
    &:active {
      font-weight: 500;

      ${ActiveCircle} {
        visibility: visible;
        svg {
          opacity: 1;
          width: 4px;
          height: 4px;
        }
      }
    }

    ${({ active }) =>
      active === 'Y' &&
      `
      font-size: 14px;
      color: ${white};
      font-weight: 500;

      ${ActiveCircle} {
      visibility: visible;
      svg {
        opacity: 1;
        width: 4px;
        height: 4px;
      }
    }`}

    &::last-child {
      padding-right: 0;
    }
  }
`

const MobileSubList = styled(List)`
  display: block;
  padding-left: 24px;

  ${When.Large} {
    display: none;
  }

  ${Item} {
    color: ${white};
    font-size: 14px;
    line-height: 27px;

    ${StyledLink} {
      padding-bottom: 10px;
    }
  }

  padding-bottom: 6px;
`

const Nav = styled.nav`
  position: absolute;
  width: 100%;
  max-height: calc(100vh - 88px);
  overflow-y: auto;
  top: 88px;
  left: 0;
  background: ${black};
  text-align: center;
  display: ${({ display }) => (display === 'Y' ? 'block' : 'none')};
  ${({ display, openByDefault }) =>
    display === 'Y'
      ? `
  width: 100vw;
  height: 100vh;
  margin-left: -20px;
  ${
    !openByDefault
      ? `
  &  li:not(:focus-within):not(:focus) ul {
    display: none;
  }
  `
      : `ul {
    display: block !important;
  }
  &  li:not(:focus-within):not(:focus) ul {
    display: none !important;
  }
  `
  }
  `
      : ''}

  & li:focus {
    outline: none;
  }

  ${When.Large} {
    display: block;
    position: static;
    padding-top: 32px;
    width: auto;
    background: none;
  }

  ${MobileSubList} {
    ${LinkText} {
      font-size: 14px;
      font-weight: 400;
    }
  }
`

const StyledHeader = styled.header`
  width: 100%;
  position: fixed;
  z-index: 999;
  top: 0;
`

const SubNavContainer = styled.div`
  position: absolute;
  display: none;
  width: 100%;
  height: 64px;
  top: 88px;
  background-color: #f5f5f5;
  visibility: ${({ show }) => (show ? 'visible' : 'hidden')};
  opacity: ${({ show }) => (show ? '1' : '0')};
  transition: visibility 0s linear 0.2s, opacity 0.2s linear 0s;

  ${When.Large} {
    display: block;
  }

  ${InnerContainer} {
    justify-content: center;

    ${Nav} {
      padding-top: 20px;

      ${LinkText} {
        color: ${black};
      }
    }
  }
`

const MainNavContainer = styled.div`
  height: 88px;
  background-color: ${black};
  padding: 0 20px;

  &:hover ${SubNavContainer} {
    transition-delay: 0s;
  }

  ${When.Large} {
    padding: 0;
  }
`

const StyledGoBack = styled(GoBack)`
  display: none;
  position: absolute;
  left: 0;

  ${When.Medium} {
    display: flex;
  }
`

interface NavbarItem {
  text: string
  to: string
  active: boolean
  subItems?: NavbarItem[]
  parent?: NavbarItem
}

export default function Header({ location }) {
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false)
  const [currentSubItems, setCurrentSubItems] = useState<NavbarItem[] | null>(
    null
  )
  const {
    allStrapiProductCategory: { edges },
  } = useStaticQuery(query)

  const navItems: NavbarItem[] = []

  const productsSubItems = edges.map(({ node }) => ({
    text: node.name,
    to: `/${node.fields.slug}`,
    active: location.pathname.startsWith(`/${node.fields.slug}`),
  }))

  const partnersSubItems = [
    {
      text: 'Our Partners',
      to: `/partners`,
      active: location.pathname.startsWith(`/partners`),
    } as NavbarItem,
    {
      text: 'Testimonials',
      to: `/testimonials`,
      active: location.pathname.startsWith(`/testimonials`),
    } as NavbarItem,
  ]

  const deliverySubItems = [
    {
      text: 'Delivery',
      to: `/logistics`,
      active: location.pathname.startsWith(`/logistics`),
    } as NavbarItem,
    {
      text: 'Decorations',
      to: `/decorations`,
      active: location.pathname.startsWith(`/decorations`),
    } as NavbarItem,
  ]

  const activeSubItem = [
    ...productsSubItems,
    // ...deliverySubItems, ...partnersSubItems
  ].find(x => x.active)

  const products = {
    text: 'Products',
    to: '#',
    subItems: productsSubItems,
    active:
      location.pathname === '/' || productsSubItems.some(item => item.active),
  }

  const delivery = {
    text: 'Services',
    to: '/logistics',
    subItems: deliverySubItems,
    active:
      location.pathname === '/logistics' ||
      deliverySubItems.some(item => item.active),
  }

  const partners = {
    text: 'Partners',
    to: '/partners',
    active:
      location.pathname === '/partners' ||
      partnersSubItems.some(item => item.active),
    subItems: partnersSubItems,
  }

  productsSubItems.forEach(x => (x.parent = products))
  deliverySubItems.forEach(x => (x.parent = delivery))
  partnersSubItems.forEach(x => (x.parent = partners))

  navItems.push(products)

  navItems.push({
    text: 'About',
    to: '/about',
    active: location.pathname === '/about',
  })

  navItems.push(delivery)

  navItems.push(partners)

  navItems.push({
    text: 'Career',
    to: '/career',
    active: location.pathname === '/career',
  })

  navItems.push({
    text: 'Contacts',
    to: '/contacts',
    active: location.pathname === '/contacts',
  })

  const activeItem = navItems.find(item => item.active)

  // on hover show menu if
  const handleHover = item => {
    setCurrentSubItems(item?.subItems)
  }

  const handleLeave = () => {
    setCurrentSubItems(activeSubItem?.parent?.subItems)
  }

  const renderSubs = currentSubItems || activeSubItem?.parent?.subItems

  const hasActiveSubItem = useMemo(
    () => !!renderSubs && renderSubs.some(({ active }) => active),
    [renderSubs]
  )

  return (
    <StyledHeader onMouseLeave={() => handleLeave()}>
      <MainNavContainer>
        <InnerContainer>
          <Link to="/">
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          </Link>
          <Nav
            display={isBurgerMenuOpen ? 'Y' : 'N'}
            openByDefault={hasActiveSubItem}
          >
            <List>
              {navItems.map(item => (
                <Item
                  key={item.text}
                  active={item.active ? 'Y' : 'N'}
                  tabIndex="0"
                >
                  <StyledLink
                    onMouseEnter={() => handleHover(item)}
                    title={item.text}
                    to={item.to}
                    active={item.active ? 'Y' : 'N'}
                    onMouseDown={() => {
                      navigate(item.to)
                    }}
                  >
                    <LinkText>{item.text}</LinkText>
                    <ActiveCircle>
                      <CircleWrapper>
                        <Circle />
                      </CircleWrapper>
                    </ActiveCircle>
                  </StyledLink>
                  {item.subItems && (
                    <MobileSubList>
                      {item.subItems.map(subItem => (
                        <Item key={subItem.to}>
                          <StyledLink
                            title={subItem.text}
                            to={subItem.to}
                            active={subItem.active ? 'Y' : 'N'}
                          >
                            <LinkText>{subItem.text}</LinkText>
                            <ActiveCircle>
                              <CircleWrapper>
                                <Circle />
                              </CircleWrapper>
                            </ActiveCircle>
                          </StyledLink>
                        </Item>
                      ))}
                    </MobileSubList>
                  )}
                </Item>
              ))}
            </List>
          </Nav>
          <LanguageSwitcherWrapper>
            <LanguageSwitcher />
          </LanguageSwitcherWrapper>
          <BurgerMenuButton
            onClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)}
          >
            <ActiveItemText>
              {activeSubItem
                ? activeSubItem.text
                : activeItem && activeItem.text}
            </ActiveItemText>
            {isBurgerMenuOpen ? <Cross /> : <BurgerMenu />}
          </BurgerMenuButton>
        </InnerContainer>
        <SubNavContainer show={renderSubs}>
          <InnerContainer>
            {hasActiveSubItem && <StyledGoBack />}
            <Nav>
              <List>
                {renderSubs &&
                  renderSubs.map(item => (
                    <Item key={item.to}>
                      <StyledLink
                        title={item.text}
                        to={item.to}
                        active={item.active ? 'Y' : 'N'}
                      >
                        <LinkText>{item.text}</LinkText>
                      </StyledLink>
                    </Item>
                  ))}
              </List>
            </Nav>
          </InnerContainer>
        </SubNavContainer>
      </MainNavContainer>
    </StyledHeader>
  )
}

const query = graphql`
  query {
    allStrapiProductCategory(sort: { fields: orderNumber, order: ASC }) {
      edges {
        node {
          name
          fields {
            slug
          }
        }
      }
    }
  }
`
