import React, { MouseEventHandler } from 'react'
import styled from 'styled-components'
import { black, white } from '../../styling/colors'
import { ArrowLeft, ArrowRight } from '../icons'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  transition: opacity .6s;
  ${({ disabled }) => disabled && 'opacity: 0.24; pointer-events: none;'}
  cursor: pointer;
`

const BorderWrapper = styled.div`
  width: 56px;
  height: 56px;
  box-sizing: border-box;
  padding: ${({ type }) => (type === 'gradient' ? '1px' : '0')};
  border-radius: 50%;
  position: relative;
  cursor: pointer;

  &:hover:before {
    background: ${props => props.color};
    cursor: pointer;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: background 0.4s;
    background: transparent;
    cursor: pointer;
  }

  ${({ type, color, direction }) =>
    type === 'gradient' &&
    `background: ${
      color === black
        ? `linear-gradient(to ${direction === 'right' ? 'left' : 'right'}, #616161 -3.77%, rgba(97, 97, 97, 0) 106.78%);`
        : `linear-gradient(to ${direction === 'right' ? 'left' : 'right'}, #FAFAFA 0%, rgba(250, 250, 250, 0) 100%);`
    }
    transition: background 0.4s;
    &:hover,
    &:active {
      background-color: ${color};
    }
  `}

  ${({ type, color }) =>
    type === 'boldSolid' &&
    `background: ${color};
    transition: all 0.2s ease-out;
    &:hover,
    &:active {
      width: 64px;
      height: 64px;
    }
  `}
`

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: ${props => props.backgroundColor};
  border-radius: 50%;
  border: 0;
  outline: 0;
  position: relative;
  cursor: pointer;

  svg {
    padding: ${props => props.iconPadding};
    cursor: pointer;
  }
`

interface Props {
  direction: 'left' | 'right'
  colors: 'whiteOnBlack' | 'outlineWhiteOnBlack' | 'blackOnWhite' | 'outlineOnWhite'
  onClick?: MouseEventHandler<Element>
  disabled?: boolean
  className?: string
}

export default function ButtonSlider({
  direction,
  colors,
  onClick,
  disabled,
  className,
}: Props) {
  const iconPadding = direction === 'right' ? '0 0 0 2px' : '0 2px 0 0'
  let backgroundColor = null
  let borderColor = null
  let iconColor = null
  if (colors === 'outlineWhiteOnBlack') {
    backgroundColor = black
    borderColor = white
    iconColor = white
  }
  if (colors === 'outlineOnWhite') {
    backgroundColor = white
    borderColor = black
    iconColor = black
  }
  if (colors === 'blackOnWhite') {
    backgroundColor = black
    borderColor = black
    iconColor = white
  }

  if (colors === 'whiteOnBlack') {
    backgroundColor = white
    borderColor = white
    iconColor = black
  }

  const button = (
    <StyledButton
      disabled={disabled}
      backgroundColor={backgroundColor}
      iconPadding={iconPadding}
    >
      {direction === 'right' && <ArrowRight stroke={iconColor} />}
      {direction === 'left' && <ArrowLeft stroke={iconColor} />}
    </StyledButton>
  )

  return (
    <Container className={className} disabled={disabled} onClick={onClick}>
      <BorderWrapper
        type={colors === 'blackOnWhite' || colors === 'whiteOnBlack' ? 'boldSolid' : 'gradient'}
        color={borderColor}
        direction={direction}
      >
        {button}
      </BorderWrapper>
    </Container>
  )
}
