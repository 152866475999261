import React from 'react'
import styled from 'styled-components'
import { black, white } from '../../styling/colors'

const BorderWrapper = styled.div`
  height: 56px;
  width: 192px;
  background: linear-gradient(
    90deg,
    #616161 -3.77%,
    rgba(97, 97, 97, 0) 106.78%
  );
  box-sizing: border-box;
  padding: 1px;
  border-radius: 28px;
  position: relative;

  &:hover:before {
    background: ${black};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 28px;
    transition: background 0.4s;
    background: transparent;
  }
`

const StyledButton = styled.button`
  display: block;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 0 28px;
  white-space: nowrap;
  box-sizing: border-box;
  background-color: ${white};
  border-radius: 28px;
  border: 0;
  outline: 0;
  cursor: pointer;
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export default function Button({ children, className, onClick }) {
  return (
    <BorderWrapper className={className}>
      <StyledButton onClick={onClick}>{children}</StyledButton>
    </BorderWrapper>
  )
}
